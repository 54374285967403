
import {
  Vue, Watch, Component, Prop,
} from 'vue-property-decorator';
import { Filter } from '@/types/ListTypes';
import ActionButton from '@/components/Button/Button.vue';
import Date from '@/components/formElements/fields/Date.vue';
import Chip from '@/components/commonComponents/Chip.vue';

interface RangeFilter {
  start?: string|null,
  end?: string|null,
  'include_null'?: boolean,
}

@Component({
  name: 'DateFilter',
  components: {
    Chip,
    Date,
    ActionButton,
  },
})
export default class DateFilter extends Vue {
  @Prop({ required: true })
  private readonly filter!: Filter;

  private inputValue: RangeFilter = {
    start: this.min,
    end: this.max,
    include_null: false,
  };

  private get min(): string {
    const minValue = this.filter.metadata?.min;

    if (typeof minValue !== 'string') {
      throw Error('The given value isn\'t a string and therefor can\'t contain a date');
    }

    return minValue;
  }

  private get max(): string {
    const maxValue = this.filter.metadata?.max;

    if (typeof maxValue !== 'string') {
      throw Error('The given value isn\'t a string and therefor can\'t contain a date');
    }

    return maxValue;
  }

  @Watch('filter.data', { immediate: true })
  private syncFilterDataWithInputData(newValue: RangeFilter): void {
    if (newValue?.start === null
      && newValue?.end === null
    ) {
      this.inputValue.start = this.min;
      this.inputValue.end = this.max;
    }

    this.inputValue.include_null = (this.filter.data as Record<string, unknown>)?.include_null === true;
  }

  protected applyFilter(): void {
    this.filter.data = this.inputValue;
    this.filter.applied = true;
    this.$emit('fetchData');
  }
}
