
import {
  Component,
  Prop,
  PropSync,
  Vue,
  Watch,
} from 'vue-property-decorator';
import Message from '@/components/mixins/Message.vue';

export interface ChipItem {
  id: number,
  text: string,
  textColor?: string,
  chipColor?: string,
  prefix?: string,
}

export interface AutocompleteItem extends ChipItem {
  disabled?: boolean,
  divider?: boolean,
  header?: string,
}

type State = 'VIEW'|'UPDATE'|'CREATE';

@Component({
  name: 'Chip',
})
export default class Chip extends Vue {
  // ID of the label, -1 by default meaning that no ID is passed
  @Prop({ default: -1 })
  private readonly id!: number;

  // Text for showing in the label, default is empty
  @Prop({ default: '' })
  private readonly text!: string;

  // Text color
  @Prop({ default: '#FFFFFF' })
  private readonly textColor!: string;

  @Prop({ default: '#e04f1a' })
  private readonly chipColor!: string

  @Prop({ default: false })
  private readonly removable!: boolean;

  @Prop({ default: false })
  private readonly creatable!: boolean;

  @Prop({ default: false })
  private readonly creatableCustom!: boolean;

  @Prop({ default: false })
  private readonly updatable!: boolean;

  @Prop({ default: false })
  private readonly updatableCustom!: boolean;

  @Prop({ default: '' })
  private readonly prefix!: string;

  @Prop()
  private readonly prependIcon: string | undefined

  @PropSync('selectableItems', { default: () => [] })
  private items!: Array<AutocompleteItem>;

  private selectedChip: ChipItem = { id: this.id, text: this.text }

  private state: State = 'VIEW';

  private autoCompleteState: 'OPEN' | 'CLOSE' = 'CLOSE';

  mounted(): void {
    if (this.id === -1) {
      this.state = 'CREATE';
    }
  }

  @Watch('selectedChip')
  private handleInput(input: { target: { value: string } } | ChipItem): void {
    if (this.state === 'UPDATE' && this.updatable && 'id' in input) {
      this.$emit('update', this.id, input.id);
    } else if (this.state === 'UPDATE' && this.updatableCustom && 'target' in input) {
      this.$emit('update-custom', this.id, input.target.value);
    } else if (this.state === 'CREATE' && this.creatable && 'text' in input) {
      this.$emit('create', input.id);
    } else if (this.state === 'CREATE' && this.creatable && 'target' in input && input.target.value === this.selectedChip.text) {
      this.$emit('create', this.selectedChip.id);
    } else if (this.state === 'CREATE' && this.creatableCustom && 'target' in input) {
      this.$emit('create-custom', input.target.value);
    } else {
      Message.error(this.$t('generic.error.occurred'));
    }
  }

  /**
   * Remove an existing chip from
   */
  removeChip(): void {
    this.$emit('remove', this.id);
  }

  private closeAutocomplete(): void {
    this.autoCompleteState = 'CLOSE';
    this.state = this.id === -1 ? 'CREATE' : 'VIEW';
  }

  private openAutocomplete(): void {
    if (this.items.length <= 0) {
      this.$emit('getItems');
    }
    this.autoCompleteState = 'OPEN';
  }

  changeToEdit(): void {
    this.state = 'UPDATE';
    this.autoCompleteState = 'OPEN';
  }
}
