var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"pt-3 h-auto"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('date',{staticClass:"mt-0 pt-0",attrs:{"id":"start-date-range-filter","value":_vm.inputValue.start,"property":{
            type: 'date',
            title: _vm.filter.metadata?.labels?.start,
            format: 'date'
          },"server-locale":_vm.$vuetify.lang.current,"required":false},on:{"update:value":function($event){return _vm.$set(_vm.inputValue, "start", $event)}}}),_c('v-spacer'),_c('date',{staticClass:"mt-0 pt-0",attrs:{"id":"end-date-range-filter","value":_vm.inputValue.end,"property":{
            type: 'date',
            title: _vm.filter.metadata?.labels?.end,
            format: 'date'
          },"server-locale":_vm.$vuetify.lang.current,"required":false},on:{"update:value":function($event){return _vm.$set(_vm.inputValue, "end", $event)}}}),_c('v-checkbox',{staticClass:"pl-8 mt-0 pt-2",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(`<i>[ ${_vm.filter.metadata?.labels?.empty} ]</i>`)}})])]},proxy:true}]),model:{value:(_vm.inputValue.include_null),callback:function ($$v) {_vm.$set(_vm.inputValue, "include_null", $$v)},expression:"inputValue.include_null"}})],1)],1),_c('v-row',[_c('v-col',[_c('action-button',{attrs:{"title":(_vm.filter.metadata?.labels?.apply).toString()},on:{"executeAction":_vm.applyFilter}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }